import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {debounce} from 'lodash'
import {Form, Input, Radio, Space, message, Checkbox} from 'antd'

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import OrderSuccess from '@/components/OrderSuccess'
import CartOrderSummary from '@/components/CartOrderSummary'

import {
  getCart,
  submitOrder,
  getWarehouse, applyDiscountCode
} from '@/api/req-api';
import {getFormatMoney, getPaymentTypeList, getShoppingMethodList} from '@/common';

import {DELIVERY_KEY} from '@/constants'
import {fetchOrderCart, removeCartItem, updateCartItemQuantity} from '@/store/modules/cart.slice';
import {fetchAddressList, fetchDefaultAddress} from '@/store/modules/address.slice';
import {CloseCircleOutlined} from "@ant-design/icons";
import moment from "moment";
import {fetchUserInfo} from "@/store/modules/common.slice";

const methodList = getShoppingMethodList()
const paymentTypeList = getPaymentTypeList()
const defaultPromo = {
  value: '',
  message: '',
  isValid: false,
  offRate: 0,
}
export default function ShoppingCart() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const cartState = useSelector(state => state.cart)
  const addressState = useSelector(state => state.address)
  const rootClassName = commonState.isMobile ? 'shopping-cart-mobile-container' : 'shopping-cart-container'
  
  const isBypassPayment = commonState.userInfo?.BypassPayment
  const {availableCredit} = commonState;
  const {orderCartInfo, orderCartProductList} = cartState
  const {customerDetail, defaultAddress} = addressState
 
  const [method, setMethod] = useState(methodList[0].value);
  const onMethodChange = event => {
    setMethod(event.target.value);
  };
  const isDelivery = useMemo(
    () => method === DELIVERY_KEY, 
    [method]
  )
  
  const isShowPaymentInformation = !isBypassPayment && isDelivery
  // const isShowPaymentInformation = false; // 没有支付，先隐藏
  const [warehouseList, setWarehouseList] = useState([])
  const [addressList, setAddressList] = useState([])
  const [useCredit, setUseCredit] = useState(false)
  const [promo, setPromo] = useState({...defaultPromo})

  const notDefaultAddressList = useMemo(() => {
    return customerDetail.filter(item => !item.IsDefault && item.CustomerDetail_id !== defaultAddress.CustomerDetail_id)
  }, [customerDetail, defaultAddress])
  const getAddressList = () => {
    dispatch(fetchAddressList())
  }
  const getDefaultAddress = () => {
    dispatch(fetchDefaultAddress())
  }
  const refreshUserInfo = () => {
    dispatch(fetchUserInfo())
  }
  
  const useAddressList = useMemo(() => {
    return isDelivery ? addressList : warehouseList
  }, [isDelivery, addressList, warehouseList])

  useEffect(() => {
    refreshUserInfo()
    getAddressList()
    getDefaultAddress()
  }, [])
  useEffect(() => {
    const addressList1 = [{
      label: `${defaultAddress.Street}, ${defaultAddress.City}, ${defaultAddress.State} ${defaultAddress.Zip}`,
      value: defaultAddress.CustomerDetail_id,
    }]
    const addressList2 = notDefaultAddressList.map((item) => {
      return {
        label: `${item.Street}, ${item.City}, ${item.State} ${item.Zip}`,
        value: item.CustomerDetail_id,
      }
    })
    setAddressList([...addressList1, ...addressList2])
  }, [notDefaultAddressList, defaultAddress])
  const [address, setAddress] = useState('');
  useEffect(() => {
    setAddress(useAddressList[0]?.value || '')
  }, [useAddressList])
  const onAddressChange = event => {
    setAddress(event.target.value);
  };
  const [paymentType, setPaymentType] = useState(paymentTypeList[0].value);
  const onPaymentTypeChange = event => {
    setPaymentType(event.target.value);
  };
  
  const [cartInfo, setCartInfo] = useState(null)
  const getCartByApi = (data = {}) => {
    const params = {
      SalesOrder_id: orderCartInfo.SalesOrder_id,
      Sales_Order_Lines: orderCartProductList,
      ShippingMethod: method,
      Tip: 0,
      TipPercentage: 0,
      TotalPrice: orderCartInfo.TotalPrice,
      IsCreditUsed: useCredit,
      PromoCode: promo.value === defaultPromo.value ? null : promo.value,
      ...data,
    }
    getCart(params).then(res => {
      setCartInfo(res.data)
    })
  }
  const getOrderCartByApi = () => {
    dispatch(fetchOrderCart())
  }
  const getWarehouseByApi = () => {
    getWarehouse().then(res => {
      const resData = res.data
      const formatData = {
        label: `${resData.Street}, ${resData.City}, ${resData.State} ${resData.Zip}`,
        value: '1'
      }
      setWarehouseList([formatData])
    })
  }
  useEffect(() => {
    getOrderCartByApi()
    // getWarehouseByApi()
  }, [])
  useEffect(() => {
    orderCartInfo && getCartByApi()
  }, [orderCartInfo, method])
  
  const handleRemoveItemClick = item => {
    dispatch(removeCartItem({item}))
      .then(() => {
        message.success(t('Removed successfully'))
      })
  }
  
  const handleQuantityChange = debounce((value, item) => {
    
    dispatch(updateCartItemQuantity({item, quantity: value}))
  }, 300)

  const orderSuccessRef = useRef(null);
  const formRef = useRef(null);
  const initialValues = {
    paymentType: undefined,
    cardholderName: 'Name',
    cardNumber: ('4111 1111 1111 1111').replaceAll(' ', ''),
    expirationDate: '08/24',
    cvv: '1234'
  }
  const resetFields = () => formRef.current?.resetFields()
  const onFormFinish = (values) => {
    const orderDate = new Date();
    const params = {
      Deliver_CustomerDetail_id: isDelivery ? address : null,
      Billing_CustomerDetail_id: isDelivery ? address : undefined,
      warehouse_zipcode: '98007',
      Sales_Order_Lines: orderCartProductList,
      FinalPrice: cartInfo.FinalPrice,
      TotalPrice: cartInfo.TotalPrice,
      GrandTotal: cartInfo.GrandTotal,
      SalesOrder_id: orderCartInfo.SalesOrder_id,
      ShippingMethod: method,
      PaymentMethod: isBypassPayment ? 'bypass' : paymentType,
      terminal: 'ONLINE',
      PromoCode: promo.value === defaultPromo.value ? null : promo.value,
      Savings: cartInfo.Savings,
      DispatchDate: moment(orderDate.setDate(orderDate.getDate() + 2)).format('YYYY-MM-DD HH:mm:ss'),
      TimeSlot: '2024-08-02 14:00:00',
      Note: undefined,
      Tip: cartInfo.Tip,
      TipPercentage: cartInfo.TipPercentage,
      IsCreditUsed: useCredit,
      CreditAmount: cartInfo?.CreditAmount,
    }
    
    if (isShowPaymentInformation) {
      params.card_reference = {
        cardNum: values.cardNumber,
        expDate: values.expirationDate,
        card_holder_name: values.cardholderName,
        cvv: values.cvv,
        salesOrderId: orderCartInfo.SalesOrder_id
      }
    }
    submitOrder(params).then(() => {
      getOrderCartByApi()
      resetFields()
      if (params.IsCreditUsed) {
        dispatch(fetchUserInfo())
      } 
      orderSuccessRef.current?.showModal()
    })
  }
  const handlePlaceAnOrderClick = () => {
    if (!orderCartProductList?.length) {
      return message.warning(t('Please add the product to the shopping cart first'))
    }
    if (!isShowPaymentInformation) {
      onFormFinish()
    } else {
      formRef.current?.submit()
    }
  }
  
  const handleModalCancel = () => {
    navigate('/')
  }
  
  const renderPaymentType = () => <div className={'shopping-cart-left-delivery-payment-type-container'}>
    <div className={'shopping-cart-left-delivery-payment-type-title'}>{t('Payment Type')}:</div>
    <Radio.Group
      className={'shopping-cart-left-delivery-payment-type-group'}
      onChange={onPaymentTypeChange}
      value={paymentType}>
      {
        paymentTypeList.map((item, index) => <Radio
          className={'shopping-cart-left-delivery-payment-type-radio-container'}
          value={item.value}
          key={`payment_type_${index}`}>
          <img src={item.label} alt="" />
        </Radio>)
      }
    </Radio.Group>
  </div>
  
  const renderPaymentInformation = () => <div
    className={'shopping-cart-left-delivery-payment-container'}>
    <div className={'shopping-cart-left-title'}>{t('Payment Information')}</div>
    <Form
      className={'shopping-cart-left-delivery-payment-form-container'}
      ref={formRef}
      layout={'vertical'}
      initialValues={initialValues}
      requiredMark={false}
      onFinish={onFormFinish}
      autoComplete="off">
      <Form.Item
        name="paymentType">
        {renderPaymentType()}
      </Form.Item>
      <Form.Item
        label={t('Cardholder Name')}
        name="cardholderName"
        rules={[
          {
            required: true,
            message: t('Please enter cardholder name'),
          },
        ]}>
        <Input
          placeholder={t('NAME ON CARD')}
        />
      </Form.Item>
      <Form.Item
        label={t('Card Number')}
        name="cardNumber"
        rules={[
          {
            required: true,
            message: t('Please enter card number'),
          },
        ]}>
        <Input
          placeholder={t('ENTER CARD NUMBER')}
        />
      </Form.Item>
      <div className={'shopping-cart-left-delivery-payment-form-row'}>
        <Form.Item
          label={t('Expiration Date')}
          name="expirationDate"
          rules={[
            {
              required: true,
              message: t('Please enter expiration date'),
            },
          ]}>
          <Input
            placeholder={t('MM/YY')}
          />
        </Form.Item>
        <Form.Item
          label={t('CVV')}
          name="cvv"
          rules={[
            {
              required: true,
              message: t('Please enter cvv'),
            },
          ]}>
          <Input
            placeholder={t('ENTER CVV')}
          />
        </Form.Item>
      </div>
    </Form>
  </div>
  const renderCreditInformation = () => {
    const handleUseCreditChange = ({target: {checked}}) => {
      setUseCredit(checked)
      getCartByApi({IsCreditUsed: checked})
    }
    return (
        !!availableCredit &&
        <div className={'shopping-cart-left-customer-credit-container'}>
          <Checkbox onChange={handleUseCreditChange}>{t('Use my credit balance') }<span className={'customer-credit-text'}>(${availableCredit})</span></Checkbox>
        </div>
    )
  }
  
  const renderDiscountInformation = () => {
    const handlePromoCodeApply = () => {
      if (promo.value) {
        applyDiscountCode(promo.value, cartInfo.TotalPrice).then(response => {
          if (!response.data.is_valid) {
            setPromo({
              ...promo, 
              value: '', 
              message: response.data.message, 
              isValid: response.data.is_valid
            })
          } else {
            setPromo({
              ...promo, 
              isValid: response.data.is_valid, 
              message: t(`Promo code successful. {percent} off your order.`)
                  .replace('{percent}', (response.data.IsPercent ? '' : '$') + response.data.OffRate + (response.data.IsPercent ? '%' : '')),
              offRate: response.data.OffRate,
            })
            getCartByApi({PromoCode: promo.value})
          }
        })
      }
    }
    const handleRemovePromoCode = () => {
      setPromo({...defaultPromo})
      getCartByApi({PromoCode: null})
    }
    const promoError = !promo.isValid && promo.message
    const promoSuccess = promo.isValid && promo.message
    return (
        <div className={'shopping-cart-left-delivery-information-container'}>
          <div className={'shopping-cart-left-title'}>{t('Discount')}</div>
          {promoError && <div className={'shopping-cart-left-promo-error-message'}>{promo.message}</div>}
          {promoSuccess && <div className={'shopping-cart-left-promo-success-message'}>
            <span className={'shopping-cart-left-promo-remove'} onClick={handleRemovePromoCode}><CloseCircleOutlined /></span>
            {promo.message}
          </div>}
          <div className={'shopping-cart-left-discount-container'}>
            <Input
                value={promo.value}
                placeholder={t('Discount code')}
                onChange={({target: {value}}) => setPromo({...promo, value})}
                onPressEnter={handlePromoCodeApply}
            />
            <div className={'shopping-cart-left-discount-apply-btn'} onClick={handlePromoCodeApply}>
              {t('Apply')}
            </div>
          </div>
        </div>
    )
  }
  return (
    <Layout
      headerLogoutIsGoHome={true}
      isDetailPage={true}
      detailPageTitle={t('Shopping Cart')}>
      <div className={rootClassName}>
        <div className={'shopping-cart-left-container'}>
          <div className={'shopping-cart-left-delivery-information-container'}>
            <div className={'shopping-cart-left-title'}>{t('Delivery Information')}</div>
            <div className={'shopping-cart-left-delivery-information-method-container'}>
              <div className={'shopping-cart-left-delivery-information-method-title'}>{t('Method')}</div>
              <Radio.Group onChange={onMethodChange} value={method}>
                {
                  methodList.map((item, index) => <Radio
                    value={item.value}
                    key={`method_${index}`}>
                    {t(item.label)}
                  </Radio>)
                }
              </Radio.Group>
            </div>
            <div className={'shopping-cart-left-delivery-information-address-container'}>
              <div className={'shopping-cart-left-delivery-information-address-title'}>{t('Address')}</div>
              <Radio.Group onChange={onAddressChange} value={address}>
                <Space direction="vertical">
                  {
                    useAddressList.map((item, index) => <Radio
                      value={item.value}
                      key={`method_${index}`}>
                      {item.label}
                    </Radio>)
                  }
                </Space>
              </Radio.Group>
            </div>
          </div>
          {isShowPaymentInformation && renderPaymentInformation()}
          {/* {renderCreditInformation()} */}
          {renderDiscountInformation()}
        </div>
        <CartOrderSummary
          className={'shopping-cart-right-container'}
          productList={orderCartProductList}
          onQuantityChange={handleQuantityChange}
          onRemoveItemClick={handleRemoveItemClick}
          onBtnClick={handlePlaceAnOrderClick}
          subtotal={cartInfo?.TotalPrice}
          tax={cartInfo?.Tax}
          promotion={cartInfo?.Savings}
          shipping={cartInfo?.Delivery}
          total={cartInfo?.GrandTotal}
          credit={cartInfo?.CreditAmount}
          promotionProduct={cartInfo?.Promotions}
        />
      </div>
      <OrderSuccess ref={orderSuccessRef} onModalCancel={handleModalCancel} />
    </Layout>
  )
}
