import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next'
import {cloneDeep, kebabCase} from 'lodash'
import {Menu, message} from 'antd'

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ContentHeader from '@/components/ContentHeader'
import OSwiper from 'src/components/OSwiper'
import ProductItem from '@/components/ProductItem'

import bannerImg from '@/assets/img/home/banner.png'

import {
  getUsingClassName,
  checkViewableLanguages,
  checkLogin,
  getFormatProductListByCartList,
  getProductItemBtnType
} from '@/common';
import {
  getBillboard,
  getCategory2,
  getCategory2B2B,
  addProductToFavoriteList,
  removeProductFromFavoriteList
} from '@/api/req-api'
import {addProductToCart, removeCartItem, updateCartItemQuantity} from '@/store/modules/cart.slice';
import {LANGUAGE_CHINESE_KEY} from '@/constants';

export default function Home() {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const cartState = useSelector(state => state.cart)
  const {productGroupMenu, zipcode, isLogged, locale } = commonState
  const rootClassName = commonState.isMobile ? 'home-mobile-container' : 'home-container'
  const {orderCartProductList} = cartState
  const [category2, setCategory2] = useState([])
  const [category2B2B, setCategory2B2B] = useState([])
  
  const useCategory2 = useMemo(() => {
    return category2.map(item => {
      return {
        ...item,
        Products: getFormatProductListByCartList(item.Products, orderCartProductList)
      }
    })
  }, [category2, orderCartProductList])
  
  const buttonCategoriesList = useMemo(
    () => useCategory2.filter(item => item.IsButton),
    [useCategory2]
  )
  const btnCategoriesList = useMemo(
    () => buttonCategoriesList.filter(item => checkViewableLanguages(item.ViewableLanguages, 'en')).splice(0, 4),
    [buttonCategoriesList]
  )

  const notBtnCategoriesList = useMemo(
    () => useCategory2.filter(item => !item.IsButton).map(item => {
      return {
        ...item,
        Products: item.Products
      }
    }),
    [useCategory2]
  )

  const [billboard, setBillboard] = useState([])
  
  useEffect(() => {
    getCategory2().then(res => {
      setCategory2(res.data)
    })
  }, [])
  useEffect(() => {
    getCategory2B2B().then(res => {
      setCategory2B2B(res.data)
    })
  }, [])
  useEffect(() => {
    getBillboard().then(res => {
      const formatData = res.data.map(item => {
        return {
          ...item,
          img: item.Image
        }
      })
      setBillboard(formatData)
    })
  }, [])

  const handleProductItemClick = item => {
    const path = `/productDetail/${item.ProductNum}`
    navigate(path);
  }
  const handleProductItemBtnClick = item => {
    dispatch(addProductToCart({item}))
  }
  const handleGroupClick = group => {
    const path = `/ourProducts/${group.name.toLowerCase()}`
    navigate(path);
  }
  const handleCat2Click = cat2 => {
    const path = `/ourProducts/category2/${cat2.ProductCategory2_id}`
    navigate(path);
  }
  
  const handleBeforeAddToCart = item => {
    checkLogin(
      navigate,
      isLogged,
      location,
      () => handleProductItemBtnClick(item)
    )
  }

  const handleChangeFavoriteStatus = item => {
    const isFavorite = item.IsFavorite
    const msg = isFavorite ? t('Cancel favorite successfully') : t('Add favorite successfully')
    const fun = isFavorite ? removeProductFromFavoriteList : addProductToFavoriteList
    const newCategory2 = cloneDeep(category2)
    let productsIdx = -1
    const newCategory2Idx = newCategory2.findIndex(it => {
      const idx = it.Products.findIndex(ite => ite.Product_id === item.Product_id)
      if (idx >= 0) {
        productsIdx = idx
        return true
      }
      return false
    })
    if (newCategory2Idx < 0 || productsIdx < 0) {
      return
    }
    fun(item).then(() => {
      message.success(msg)
      newCategory2[newCategory2Idx].Products.splice(productsIdx, 1, {...item, IsFavorite: !isFavorite})
      setCategory2(newCategory2)
    })
  }
  const handleBeforeChangeFavoriteStatus = (item, callback) => {
    checkLogin(
      navigate,
      isLogged,
      location,
      callback
    )
  }
  const handleFavoriteIconClick = item => {
    handleBeforeChangeFavoriteStatus(item, () => handleChangeFavoriteStatus(item))
  }
  
  const handleMinBtnClick = (item, qty) => {
    const newQty = qty - 1
    if (newQty < 1) {
      return dispatch(removeCartItem({item}))
    }
    dispatch(updateCartItemQuantity({item, quantity: newQty}))
  }
  
  const renderSwiperSlideContent = (item, index) => {
    return <ProductItem
      onProductItemClick={() => handleProductItemClick(item)}
      img={item.Thumbnail}
      measureUnit={item.MeasureUnit}
      measureUnit2={item.MeasureUnit_2}
      productType={item.ProductType}
      name={item.Name}
      description={item.Description}
      price={item.Price}
      isShowPrice={isLogged}
      isFormatPriceToMoney={true}
      onBtnClick={() => handleBeforeAddToCart(item)}
      isFavorited={item.IsFavorite}
      isShowFavorite={true}
      onFavoriteIconClick={() => handleFavoriteIconClick(item)}
      quantity={item.Quantity}
      btnType={getProductItemBtnType(item.Quantity)}
      onMinBtnClick={() => handleMinBtnClick(item, item.Quantity)}
      onPlusBtnClick={() => handleProductItemBtnClick(item)}
      isPromotion={item.IsPromotion}
      isOverSaleable={item.IsOverSaleable}
      stock={item.StockLevel}
      discountDescription={item.DiscountDescription}
      originalPrice={item.OriginalPrice}
    />
  }
  
  const renderSwiperSlideContent2 = (item, index) => {
    return <div className={commonState.isMobile ? "home-cat2-mobile-container" : "home-cat2-container"}>
      <div className="home-cat2" key={index} onClick={() => handleCat2Click(item)}>
        <img className="home-cat2-logo" src={item.Image} alt=""/>
      </div>
    </div>
  }
  
  // category 2
  const renderProductList = (productList, title) => {
    const className = getUsingClassName(
      ['home-product-group-container'],
      [`home-product-group-${kebabCase(title)}`]
    )
    return <div className={className} key={title}>
      <div className={'home-product-group-title'}>{title}</div>
      <div className={'home-product-group-list'}>
        <OSwiper
          data={productList}
          renderSwiperSlideContent={renderSwiperSlideContent}
          slidesPerView={commonState.isMobile ? 2 : 4}
        />
      </div>
    </div>
  }
  
  // category 2 login (B2B)
  const renderB2BProductList = (productList, title) => {
    const className = getUsingClassName(
      ['home-product-group-container'],
      [`home-product-group-${kebabCase(title)}`]
    )
    return <div className={className} key={title}>
      <div className={'home-product-group-title'}>Popular Brand</div>
      <div className={'home-product-group-list'}>
        <OSwiper
          data={productList} 
          renderSwiperSlideContent={renderSwiperSlideContent2}
          slidesPerView={commonState.isMobile ? 2 : 4}
          grid={2}
        />
      </div>
    </div>
  }

  const renderGroupList = (groups) => {
    return <div className={commonState.isMobile ? "home-group-mobile-container" : "home-group-container"}>
      {
        groups.map((group, index) => {
          return (
            <div className="home-group" key={index} onClick={() => handleGroupClick(group)}>
              <img 
                className="home-group-image"
                src={group.image} alt=""/>
              {
                (locale === LANGUAGE_CHINESE_KEY) ? 
                  <div className="home-group-name">{group.description}</div>
                :
                  <div className="home-group-name">{group.name}</div>
              }
            </div>
          )
        })
      }
    </div>
  }

  return (
    <Layout>
      <div className={rootClassName}>
        {/* here show group with logo */}
        {
          !commonState.isMobile &&
          renderGroupList(productGroupMenu)
        }
        <ContentHeader
          className={'home-header-container'}
          des={'Chubby Supply'}
          bannerList={billboard}
        />
        {/* here show category2 for login user */}
        {/*
        {
          isLogged && renderB2BProductList(category2B2B, '')
        }
        */}
        {/* category2 */}
        { notBtnCategoriesList.map((item, index) => renderProductList(item.Products, (locale === LANGUAGE_CHINESE_KEY) ? item.Description : item.Name)) }
      </div>
    </Layout>
  )
}
