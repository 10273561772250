import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {cloneDeep} from 'lodash';
import {Empty, Input, message} from 'antd';

import './index.less'

import {
  checkLogin,
  getDecodeStr,
  getEncodeStr,
  getFormatProductListByCartList,
  getProductItemBtnType,
  getUsingClassName,
} from '@/common';
import {PRODUCT_DEFAULT_LIMIT} from '@/constants';

import searchIcon from '@/assets/img/icon/search.png';
import Loading from '@/components/Loading';
import ProductItem from '@/components/ProductItem';
import {
  addProductToFavoriteList,
  getAllProducts,
  removeProductFromFavoriteList
} from '@/api/req-api';
import {addProductToCart, removeCartItem, updateCartItemQuantity} from '@/store/modules/cart.slice';

const searchKey = 'name'

const productLimit = PRODUCT_DEFAULT_LIMIT

const defaultProps = {
  className: '',
  rootPath: '',
  scrollTop: 0,
  isEditOrder: false,
  cartProductList: [],
  onAddProduct: () => {},
  onEditProduct: () => {},
  onRemoveProduct: () => {},
};

const SearchProductsContent = forwardRef(function (props, ref) {
  const options = Object.assign({}, defaultProps, props);
  const {
    rootPath,
    scrollTop,
    isEditOrder,
    cartProductList,
    onAddProduct,
    onEditProduct,
    onRemoveProduct,
  } = options
  
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const {t} = useTranslation()

  const searchName = searchParams.get(searchKey)
  const name = searchName ? getDecodeStr(searchName) : ''

  const [products, setProducts] = useState([])
  const commonState = useSelector(state => state.common)
  const {isLogged} = commonState

  const [loading, setLoading] = useState(false)
  const searchInputRef = useRef()
  const touchBottom = useRef()

  // 设置默认值为null，只有初次进页面会是默认值，清除选择后为undefined
  const [sortByVal, setSortByVal] = useState(null)
  const [sortByStock, setSortByStock] = useState('all')
  // 设置默认值为0，只有初次进页面会是默认值，切换组或者分类会设置为1
  const [pageNum, setPageNum] = useState(0)
  const [totalPages, setTotalPages] = useState(0)

  const useProducts = useMemo(() => {
    return getFormatProductListByCartList(products, cartProductList);
  }, [products, cartProductList])

  useEffect(() => {
    if(!name) {
      setProducts([])
      setPageNum(1)
      return
    }
    fetchAllProducts(name, sortByVal, sortByStock, 1,)
  }, [name])

  const fetchAllProducts = (name, sortByVal, sortByStock, pageNum) => {
    setLoading(true)
    getAllProducts(name || undefined, sortByVal, sortByStock, pageNum).then(response => {
      const resData = response.data.Products
      setTotalPages(response.data.pages)
      const newList = []
      if (pageNum > 1) {
        newList.push(...products)
      }
      newList.push(...resData)
      setProducts(newList)
      setLoading(false)
      setPageNum(pageNum);
    })
  }
 
  useEffect(() => {
    // 如果是默认值，不需要调接口，别的地方会调
    if (pageNum < 1) {
      return
    }
    // initPageData()
  }, [pageNum])

  const handleProductItemClick = item => {
    if (isEditOrder) {
      const path = `/editOrderProductDetail/${item.ProductNum}`
      navigate(path)
      return 
    }
    const path = `/productDetail/${item.ProductNum}`
    if (location.pathname === path) {
      return
    }
    navigate(path)
  }

  const handleEditOrderMinBtnClick = (item, qty) => {
    const newQty = qty - 1
    if (newQty < 1) {
      return onRemoveProduct(item)
    }
    onEditProduct(item, newQty)
  }
  const handleMinBtnClick = (item, qty) => {
    if (isEditOrder) {
      return handleEditOrderMinBtnClick(item, qty)
    }
    const newQty = qty - 1
    if (newQty < 1) {
      return dispatch(removeCartItem({item}))
    }
    dispatch(updateCartItemQuantity({item, quantity: newQty}))
  }
  const handleAddToCart = item => {
    if (isEditOrder) {
      return onAddProduct(item)
    }
    dispatch(addProductToCart({item}))
  }

  const handleBeforeAddToCart = item => {
    checkLogin(
      navigate,
      isLogged,
      location,
      () => handleAddToCart(item)
    )
  }

  const handleChangeFavoriteStatus = item => {
    const isFavorite = item.IsFavorite
    const msg = isFavorite ? t('Cancel favorite successfully') : t('Add favorite successfully')
    const fun = isFavorite ? removeProductFromFavoriteList : addProductToFavoriteList
    const newProducts = cloneDeep(products)
    const idx = newProducts.findIndex(ite => ite.Product_id === item.Product_id)
    if (idx < 0) {
      return
    }
    fun(item).then(() => {
      message.success(msg)
      newProducts.splice(idx, 1, {...item, IsFavorite: !isFavorite})
      setProducts(newProducts)
    })
  }
  const handleBeforeChangeFavoriteStatus = (item, callback) => {
    checkLogin(
      navigate,
      isLogged,
      location,
      callback
    )
  }
  const handleFavoriteIconClick = item => {
    handleBeforeChangeFavoriteStatus(item, () => handleChangeFavoriteStatus(item))
  }

  const handleSearch = () => {
    const keywords = searchInputRef.current.input.value
    const searchParams = new URLSearchParams({[searchKey]: getEncodeStr(keywords)})
    navigate(`${rootPath}?${searchParams}`, {replace: true})
  }

  const handleShowMore = () => {
    fetchAllProducts(name, sortByVal, sortByStock, pageNum + 1)
  }

  const renderRightSearchIcon = () => <div className={'products-content-right-search-icon-container'}>
    <img src={searchIcon} alt="" onClick={handleSearch}/>
  </div>

  const renderProductGroupItem = (item, index) => <div
    className={'products-content-right-product-group-item-container'}
    key={`product_group_item_${index}`}>
    <ProductItem
      img={item.Thumbnail}
      productType={item.ProductType}
      measureUnit={item.MeasureUnit}
      measureUnit2={item.MeasureUnit_2}
      price={item.Price}
      name={item.Name}
      description={item.Description}
      onProductItemClick={() => handleProductItemClick(item)}
      isShowPrice={isLogged}
      onBtnClick={() => handleBeforeAddToCart(item)}
      isFormatPriceToMoney={true}
      isFavorited={item.IsFavorite}
      isShowFavorite={true}
      onFavoriteIconClick={() => handleFavoriteIconClick(item)}
      quantity={item.Quantity}
      btnType={getProductItemBtnType(item.Quantity)}
      onMinBtnClick={() => handleMinBtnClick(item, item.Quantity)}
      onPlusBtnClick={() => handleAddToCart(item)}
      isPromotion={item.IsPromotion}
      stock={item.StockLevel}
      isOverSaleable={item.IsOverSaleable}
      discountDescription={item.DiscountDescription}
      originalPrice={item.OriginalPrice}
    />
  </div>
  const renderProductGroup = () => <div className={'products-content-right-product-group-container'}>
    {
      !products.length &&<div className='empty_img'><Empty  image={Empty.PRESENTED_IMAGE_SIMPLE}/></div>
    }
    {
      !!products.length &&
      <>
        <div className={'products_content_right_product_group_list'}>
          {useProducts.map((item, index) => renderProductGroupItem(item, index))}
        </div>
        {
          products.length >= productLimit && pageNum < totalPages &&
          <div
            className={'products-content-right-product-group-btn'}
            onClick={handleShowMore}>
            {t('Click to show more')}
          </div>
        }
      </>
    }
  </div>

  const handlePageScroll = event => {
    if(event.target.documentElement.scrollTop >= scrollTop) {
      if (!touchBottom.current) {
      } else {
      }
    } else {
    }
  }
  
  const className = getUsingClassName(
    ['products_list'],
    [options.className]
  )

  useImperativeHandle(ref, () => ({
    handlePageScroll
  }))
  return (
    <div className={className}>
      <div className={'product_input_div'}>
        <div className={'product_input'}>
          <Input
            ref={searchInputRef}
            className={'products-content-right-search-container'}
            suffix={renderRightSearchIcon()}
            placeholder={t('Search by Product Name')}
            onPressEnter={handleSearch}
          />
        </div>
        {loading && <Loading text={'Loading Products'} />}
        {renderProductGroup()}
      </div>
    </div>
  )
})

export default SearchProductsContent
