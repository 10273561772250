import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next'
import {Checkbox, Form, Input} from 'antd';

import './index.less'
import './mobile.less'

import {getUsingClassName} from '@/common';

import {fetchUserInfo, userLogin} from '@/store/modules/common.slice';
import ForgetPassword from '@/components/ForgetPassword';
import {delCookie, getCookie, setCookie} from "@/components/Login/functions";

const defaultProps = {
  className: '',
  onRegisterAccountClick: () => {},
  onLoginSuccess: () => {},
};

const RegisterAccount = forwardRef(function (props, ref) {
  const options = Object.assign({}, defaultProps, props);

  const dispatch = useDispatch()
  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'login-mobile-container' : 'login-container'
  const {locale} = commonState

  const formRef = useRef(null);
  const forgetPasswordRef = useRef(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const cUsername = getCookie('nick');
    const cPassword =  getCookie('salt');
    if (cUsername) {
      formRef.current.setFieldValue('name', cUsername);
    }
    if (cPassword) {
      formRef.current.setFieldValue('password', cPassword); //todo 加md5
    }
  }, []);
  const onFormFinish = (values) => {
    const params = {
      password: values.password,
      username: values.name,
    }
    dispatch(userLogin(params))
      .then(({meta}) => {
        if (meta.requestStatus === 'rejected') {
          return
        }
        if (rememberMe) {
          setCookie('nick', params.username, 7);
          setCookie('salt', params.password, 7);
        } else {
          delCookie('nick');
          delCookie('salt');
        }
        dispatch(fetchUserInfo())
        options.onLoginSuccess()
      })
  }
  const resetFields = () => formRef.current?.resetFields()

  const handleLogInClick = () => {
    formRef.current?.submit()
  }
  
  const handleForgetPasswordClick = () => {
    forgetPasswordRef.current?.showModal()
  }

  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  useImperativeHandle(ref, () => ({
    resetFields,
  }))
  
  const handleRememberMe = ({target: {checked}}) => {
    setRememberMe(checked)
  }
  
  return (
    <div className={className}>
      <div className={'login-title'}><span>{t('Welcome to')}</span> Chubby Supply </div>
      <div className={'login-content-container'}>
        <Form
          className={'login-form-container'}
          ref={formRef}
          layout={'vertical'}
          requiredMark={false}
          onFinish={onFormFinish}
          autoComplete="off">
          <Form.Item
            label={t('Email address or Phone Number')}
            name="name"
            rules={[
              {
                required: true,
                message: t('Please enter your email address or phone Number'),
              },
            ]}>
            <Input
              placeholder={t('Enter your email address or phone Number')}
            />
          </Form.Item>
          <div className={'login-form-password-container'}>
            <Form.Item
              label={t('Password')}
              name="password"
              rules={[
                {
                  required: true,
                  message: t('Please enter your password'),
                },
              ]}>
              <Input.Password
                placeholder={t('Enter your password')}
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
                onKeyDown={(event)=>{
                  if(event.keyCode == 13) {
                    handleLogInClick()
                  }
                }}
                iconRender={() => null}
              />
            </Form.Item>
            <div
              className={'login-form-show-hide-password'}
              onClick={() => setPasswordVisible(prevState=> !prevState)}>
              {passwordVisible ? t('Hide') : t('Show')} {t('Password')}
            </div>
            <div className="login-form-options-wrapper">
              <div className="login-form-remember-me">
                <Checkbox onChange={handleRememberMe} name={'remember'} checked={rememberMe}>{t('Remember Me')}</Checkbox>
              </div>
              <div
                  className={'login-form-forget-password'}
                  onClick={handleForgetPasswordClick}>
                {t('Forget Password')}
              </div>
            </div>
          </div>
        </Form>
        <div className={'login-btn'} onClick={handleLogInClick}>
          {t('Log In')}
        </div>
        <div className={'login-divider'}>{t('New to our store')}</div>
        <div className={'login-btn2'} onClick={options.onRegisterAccountClick}>
          {t('Register Account')}
        </div>
      </div>
      <ForgetPassword ref={forgetPasswordRef} />
    </div>
  )
})

export default RegisterAccount
