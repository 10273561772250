import React from 'react'
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import './index.less'
import './mobile.less'

import WechatPopover from '@/components/WechatPopover'
import logoImg from '@/assets/img/logo.png'
import wechatImg from '@/assets/img/icon/wechat.png'
import instagramImg from '@/assets/img/icon/instagram.png'
import websiteImg from '@/assets/img/icon/website.png'
import {WEBSITE_LINK, IG_LINK} from '@/constants';

const defaultProps = {
  isShowFooterTop: true,
};

export default function Footer(props) {
  const options = Object.assign({}, defaultProps, props);
  
  const navigate = useNavigate()
  const location = useLocation()
  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'footer-mobile-container' : 'footer-container'
  
  const handleContactUsClick = () => {
    const path = '/contactUs'
    if (location.pathname === path) {
      return
    }
    navigate(path)
  }
  
  return (
    <div className={rootClassName} id={'page-footer'}>
      {
        options.isShowFooterTop &&
        <div className={'footer-top-container'}>
          <div className={'footer-top-content-container'}>
            <div className={'footer-top-content-left-container'}>
              <div className={'footer-top-content-left-logo-container'}>
                <img src={logoImg} alt="logo" />
              </div>
              <div className={'footer-top-content-left-des'}>
                Discover the joy of finding the perfect fit with Chubby Supply. Embrace your unique style and feel confident in every outfit. We believe that fashion should be inclusive and empowering for everyone. Join us in celebrating individuality and self-expression. Supply your wardrobe with pieces that make you feel amazing, because at Chubby Supply, we’re all about the perfect fit.</div>
              <div className={'footer-top-content-left-get-in-touch-container'}>
                <div className={'footer-top-content-title'}>{t('SOCIAL MEDIAS')}</div>
                <div className={'footer-top-content-left-get-in-touch-list'}>
                  <WechatPopover>
                    <div 
                      className={'footer-top-content-left-get-in-touch-item-container'}>
                      <img src={wechatImg} alt="wechat" />
                    </div>
                  </WechatPopover>
                  <div 
                    className={'footer-top-content-left-get-in-touch-item-container'}
                    onClick={() => window.open(IG_LINK)}>
                    <img src={instagramImg} alt="instagram" />
                  </div>
                  {/*
                  <div 
                    className={'footer-top-content-left-get-in-touch-item-container'}
                    onClick={() => window.open(WEBSITE_LINK)}>
                    <img src={websiteImg} alt="facebook" />
                  </div>
                  */}
                </div>
              </div>
            </div>
            <div className={'footer-top-content-right-container'}>
              <div className={'footer-top-content-right-working-hours-container'}>
                <div className={'footer-top-content-title'}>{t('WORKING HOURS')}</div>
                <div className={'footer-top-content-right-working-hours-item-container'}>
                  {t('Monday - Saturday')}
                  <span>: {t('8am - 5pm')}</span>
                </div>
                <div className={'footer-top-content-right-working-hours-item-container'}>
                  {t('Sunday')}
                  <span>: {t('9am - 3pm')}</span>
                </div>
              </div>
              <div className={'footer-top-content-right-contact-us-container'}>
                <div className={'footer-top-content-title footer-link'} onClick={handleContactUsClick}>{t('CONTACT US')}</div>
                <div className={'footer-top-content-right-contact-us-title'}>
                  123 Address Ave Los Angeles, CA 90000
                </div>
                <div className={'footer-top-content-right-contact-us-des'}>
                  {t('Phone')}: 000-000-0000
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div className={'footer-bottom-container'}>
        © 2024 Chubby Supply | Powered by TruckXi
      </div>
    </div>
  )
}
