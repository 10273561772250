import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {LeftOutlined} from '@ant-design/icons';

import './index.less'
import './mobile.less'

import {getFormatMoney, getPaymentTypeList, getShoppingMethodList, getUsingClassName, printElement} from '@/common';
import {getSalesInvoiceDetails, getSalesOrderDetails, getSalesInvoiceReturnDetails, printSalesInvoice, printSalesOrder, printSalesInvoiceReturn} from '@/api/req-api'

import MyOrderItem from '@/components/MyOrderItem';
import ProductItem2 from '@/components/ProductItem2';

const paymentTypeList = getPaymentTypeList()
const methodList = getShoppingMethodList()
const opBtnId = 'op-btn-id'

const defaultProps = {
  className: '',
  onBack: () => {},
  orderId: undefined,
};

export default function MyOrderDetail(props) {
  const options = Object.assign({}, defaultProps, props);

  const {t} = useTranslation()

  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'my-order-detail-mobile-container' : 'my-order-detail-container'

  const printContentRef = useRef(null)
  
  const [orderDetailInfo, setOrderDetailInfo] = useState({})
  const [invoiceDetailInfo, setInvoiceDetailInfo] = useState({})
  const [invoiceReturnInfo, setInvoiceReturnInfo] = useState({})
  const addressInfo = useMemo(() => {
    const shippingAddress = orderDetailInfo.Shipping_Address || {}
    const {Street, City, State, Zip, Telephone, FirstName} = shippingAddress
    return {
      name: FirstName,
      phone: Telephone,
      street: Street,
      city: City,
      state: State,
      zip: Zip,
    }
  }, [orderDetailInfo.Shipping_Address])
  const shoppingMethodLabel = useMemo(() => {
    return methodList.find(item => item.value === orderDetailInfo.ShippingMethod)?.label
  }, [orderDetailInfo.ShippingMethod])
  const {orderId} = options
  
  // TODO 先隐藏
  const isShowPaymentInformation = false
  
  useEffect(() => {
    getSalesOrderDetails(orderId).then(res => {
      setOrderDetailInfo(res.data)
      if (!!res.data.invoice_total) {
        getSalesInvoiceDetails(orderId).then(res => {
          setInvoiceDetailInfo(res.data)
        }).catch(() => {
          setInvoiceDetailInfo({})
        })
      }
      
      if(!!res.data.return_total) {
        getSalesInvoiceReturnDetails(orderId).then(res => {
          setInvoiceReturnInfo(res.data)
        }).catch(()=> {
          setInvoiceReturnInfo({})
        })
      }
    })
  }, [orderId])
  
  const handlePrintOrderDetail = orderId => {
    const btn = document.getElementById(opBtnId)
    btn.style.display = 'none'
    printSalesOrder(orderId).then(response => {
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      document.body.append(iframe)
      const iframeWindow = iframe.contentWindow || iframe
      const loaded = () => {
        iframe.focus()
        iframeWindow.print()
      }
      iframe.addEventListener('load', loaded)
      iframe.src = window.URL.createObjectURL(response.data)
    });
  }
  
  const handlePrintInvoiceDetail = invoiceId => {
    const btn = document.getElementById(opBtnId)
    btn.style.display = 'none'
    printSalesInvoice(invoiceId).then(response => {
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      document.body.append(iframe)
      const iframeWindow = iframe.contentWindow || iframe
      const loaded = () => {
        iframe.focus()
        iframeWindow.print()
      }
      iframe.addEventListener('load', loaded)
      iframe.src = window.URL.createObjectURL(response.data)
    });
  }

  const handlePrintInvoiceReturnDetail = returnId => {
    const btn = document.getElementById(opBtnId)
    btn.style.display = 'none'
    printSalesInvoiceReturn(returnId).then(response => {
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      document.body.append(iframe)
      const iframeWindow = iframe.contentWindow || iframe
      const loaded = () => {
        iframe.focus()
        iframeWindow.print()
      }
      iframe.addEventListener('load', loaded)
      iframe.src = window.URL.createObjectURL(response.data)
    });
  }
  
  const renderProductItem = (item, index, isReturn = false) => {
    return <ProductItem2 
      isOrder={true}
      key={`product_list_${index}`}
      // packageType={item.MeasureUnit}
      productType={item.ProductType}
      measureUnit={item.MeasureUnit}
      measureUnit2={item.MeasureUnit_2}
      
      packageType={item.PackageType}
      img={item.Thumbnail}
      des={item.Name}
      price={item.UnitPrice}
      totalPrice={isReturn ? item.ReturnTotalPrice : item.TotalPrice}
      isShowPrice={true}
      isFormatPriceToMoney={true}
      quantity={isReturn ? item.ReturnQuantity : item.Quantity}
    />
  }
  const renderOrderSummary = () => <div 
    className={'my-order-detail-order-summary-container'}>
    <div className={'my-order-detail-order-summary-title'}>{t('Order Summary')}</div>
    <div className={'my-order-detail-order-summary-line'}></div>
    <div className={'my-order-detail-order-summary-product-list-container'}>
      {orderDetailInfo.Sales_Order_Line?.map((item, index) => renderProductItem(item, index))}
    </div>
    <div className={'my-order-detail-order-summary-line'}></div>
    <div className={'my-order-detail-order-summary-order-info-container'}>
      <div className={'my-order-detail-order-summary-order-info-item-container'}>
        <div className={'my-order-detail-order-summary-order-info-item-title'}>{t('Subtotal')}</div>
        <div className={'my-order-detail-order-summary-order-info-item-val'}>${getFormatMoney(orderDetailInfo?.TotalPrice)}</div>
      </div>
      <div className={'my-order-detail-order-summary-order-info-item-container'}>
        <div className={'my-order-detail-order-summary-order-info-item-title'}>{t('Tax')}</div>
        <div className={'my-order-detail-order-summary-order-info-item-val'}>${getFormatMoney(orderDetailInfo?.TaxAmount)}</div>
      </div>
      <div className={'my-order-detail-order-summary-order-info-item-container'}>
        <div className={'my-order-detail-order-summary-order-info-item-title'}>{t('Promotion')}</div>
        <div className={'my-order-detail-order-summary-order-info-item-val'}>${getFormatMoney(orderDetailInfo?.Savings)}</div>
      </div>
      <div className={'my-order-detail-order-summary-order-info-item-container'}>
        <div className={'my-order-detail-order-summary-order-info-item-title'}>{t('Shipping')}</div>
        <div className={'my-order-detail-order-summary-order-info-item-val'}>${getFormatMoney(orderDetailInfo?.Delivery)}</div>
      </div>
      {/* <div className={'my-order-detail-order-summary-order-info-item-container'}>
        <div className={'my-order-detail-order-summary-order-info-item-title'}>{t('Transaction Fee 3%')}</div>
        <div className={'my-order-detail-order-summary-order-info-item-val'}>${getFormatMoney((orderDetailInfo?.TotalPrice *0.03).toFixed(2))}</div>
      </div> */}
      <div className={'my-order-detail-order-summary-order-info-item-container'}>
        <div className={'my-order-detail-order-summary-order-info-item-title'}>{t('Credit')}</div>
        <div className={'my-order-detail-order-summary-order-info-item-val'}>${getFormatMoney(orderDetailInfo?.CreditAmount)}</div>
      </div>
      <div className={'my-order-detail-order-summary-line my-order-detail-order-summary-order-info-line'}></div>
      <div className={'my-order-detail-order-summary-order-info-item-container'}>
        <div className={'my-order-detail-order-summary-order-info-item-title'}>{t('Total')}</div>
        <div className={'my-order-detail-order-summary-order-info-item-val'}>${getFormatMoney(orderDetailInfo?.FinalPrice)}</div>
      </div>
    </div>
  </div>
  const renderInvoiceReturnSummary = () => <div 
    className={'my-order-detail-invoice-summary-container'}>
    <div className='row_num'>
      <div className={'my-order-detail-invoice-summary-title'}>{t('Sales Return Summary')}</div>
      <div className={'my-order-detail-invoice-summary-title'}>Rtn# {invoiceReturnInfo?.SRNum}</div>
    </div>
    <div className={'my-order-detail-invoice-summary-line'}></div>
    <div className={'my-order-detail-invoice-summary-product-list-container'}>
      {invoiceReturnInfo.Sales_Return_Line?.map((item, index) => renderProductItem(item, index, true))}
    </div>
    <div className={'my-order-detail-invoice-summary-line'}></div>
    <div className={'my-order-detail-invoice-summary-order-info-container'}>
      <div className={'my-order-detail-invoice-summary-order-info-item-container'}>
        <div className={'my-order-detail-invoice-summary-order-info-item-title'}>{t('Subtotal')}</div>
        <div className={'my-order-detail-invoice-summary-order-info-item-val'}>${getFormatMoney(invoiceReturnInfo?.TotalPrice)}</div>
      </div>
      <div className={'my-order-detail-invoice-summary-order-info-item-container'}>
        <div className={'my-order-detail-invoice-summary-order-info-item-title'}>{t('Tax')}</div>
        <div className={'my-order-detail-invoice-summary-order-info-item-val'}>${getFormatMoney(invoiceReturnInfo?.TaxAmount)}</div>
      </div>
      <div className={'my-order-detail-invoice-summary-order-info-item-container'}>
        <div className={'my-order-detail-invoice-summary-order-info-item-title'}>{t('Promotion')}</div>
        <div className={'my-order-detail-invoice-summary-order-info-item-val'}>${getFormatMoney(invoiceReturnInfo?.Savings)}</div>
      </div>
      <div className={'my-order-detail-invoice-summary-order-info-item-container'}>
        <div className={'my-order-detail-invoice-summary-order-info-item-title'}>{t('Shipping')}</div>
        <div className={'my-order-detail-invoice-summary-order-info-item-val'}>${getFormatMoney(invoiceReturnInfo?.Delivery)}</div>
      </div>
      <div className={'my-order-detail-invoice-summary-order-info-item-container'}>
        <div className={'my-order-detail-invoice-summary-order-info-item-title'}>{t('Restocking')}</div>
        <div className={'my-order-detail-invoice-summary-order-info-item-val'}>$({-(getFormatMoney(invoiceReturnInfo?.RestockingFee))})</div>
      </div>
      <div className={'my-order-detail-invoice-summary-line my-order-detail-invoice-summary-order-info-line'}></div>
      <div className={'my-order-detail-invoice-summary-order-info-item-container'}>
        <div className={'my-order-detail-invoice-summary-order-info-item-title'}>{t('Total')}</div>
        <div className={'my-order-detail-invoice-summary-order-info-item-val'}>${getFormatMoney(invoiceReturnInfo?.FinalPrice)}</div>
      </div>
    </div>
  </div>
  const renderInvoiceSummary = () => <div 
    className={'my-order-detail-invoice-summary-container'}>
   <div className='row_num'>
    <div className={'my-order-detail-invoice-summary-title'}>{t('Invoice Summary')}</div>
    <div className={'my-order-detail-invoice-summary-title'}>Inv# {invoiceDetailInfo?.MergedInvoiceNum?invoiceDetailInfo?.MergedInvoiceNum:invoiceDetailInfo?.InvoiceNum}</div>
   </div>
    <div className={'my-order-detail-invoice-summary-line'}></div>
    <div className={'my-order-detail-invoice-summary-product-list-container'}>
      {invoiceDetailInfo.Sales_Order_Line?.map((item, index) => renderProductItem(item, index))}
    </div>
    <div className={'my-order-detail-invoice-summary-line'}></div>
    <div className={'my-order-detail-invoice-summary-order-info-container'}>
      <div className={'my-order-detail-invoice-summary-order-info-item-container'}>
        <div className={'my-order-detail-invoice-summary-order-info-item-title'}>{t('Subtotal')}</div>
        <div className={'my-order-detail-invoice-summary-order-info-item-val'}>${getFormatMoney(invoiceDetailInfo?.TotalPrice)}</div>
      </div>
      <div className={'my-order-detail-invoice-summary-order-info-item-container'}>
        <div className={'my-order-detail-invoice-summary-order-info-item-title'}>{t('Tax')}</div>
        <div className={'my-order-detail-invoice-summary-order-info-item-val'}>${getFormatMoney(invoiceDetailInfo?.TaxAmount)}</div>
      </div>
      <div className={'my-order-detail-invoice-summary-order-info-item-container'}>
        <div className={'my-order-detail-invoice-summary-order-info-item-title'}>{t('Promotion')}</div>
        <div className={'my-order-detail-invoice-summary-order-info-item-val'}>${getFormatMoney(invoiceDetailInfo?.Savings)}</div>
      </div>
      <div className={'my-order-detail-invoice-summary-order-info-item-container'}>
        <div className={'my-order-detail-invoice-summary-order-info-item-title'}>{t('Shipping')}</div>
        <div className={'my-order-detail-invoice-summary-order-info-item-val'}>${getFormatMoney(invoiceDetailInfo?.Delivery)}</div>
      </div>
      <div className={'my-order-detail-invoice-summary-line my-order-detail-invoice-summary-order-info-line'}></div>
      <div className={'my-order-detail-invoice-summary-order-info-item-container'}>
        <div className={'my-order-detail-invoice-summary-order-info-item-title'}>{t('Total')}</div>
        <div className={'my-order-detail-invoice-summary-order-info-item-val'}>${getFormatMoney(invoiceDetailInfo?.FinalPrice)}</div>
      </div>
    </div>
  </div>
  
  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  return (
    <div className={className}>
      <div className={'my-order-detail-top-container'} onClick={options.onBack}>
        <LeftOutlined />
        <div className={'my-order-detail-top-title'}>{t('Order Detail')}</div>
      </div>
      <div
        ref={printContentRef}
        className={'my-order-detail-content-container'}>
        <MyOrderItem
          item={orderDetailInfo}
          invoice={invoiceDetailInfo}
          isShowViewOrderDetailsBtn={false}
          isShowReturnTotal = {invoiceReturnInfo && invoiceReturnInfo.Sales_Return_Line && invoiceReturnInfo.Sales_Return_Line.length > 0}
          isShowInvoiceTotal={invoiceDetailInfo && invoiceDetailInfo.Sales_Order_Line && invoiceDetailInfo.Sales_Order_Line.length > 0}
          isShowReorderBtn={true}
          opBtnId={opBtnId}
        />
        <div className={'my-order-detail-shopping-container'}>
          <div className={'my-order-detail-shopping-item-container'}>
            <div className={'my-order-detail-shopping-item-label'}>{t(shoppingMethodLabel)} {t('Name')}</div>
            <div className={'my-order-detail-shopping-item-value'}>{addressInfo.name}</div>
          </div>
          <div className={'my-order-detail-shopping-item-container'}>
            <div className={'my-order-detail-shopping-item-label'}>{t(shoppingMethodLabel)} {t('Phone')}</div>
            <div className={'my-order-detail-shopping-item-value'}>{addressInfo.phone}</div>
          </div>
          <div className={'my-order-detail-shopping-item-container'}>
            <div className={'my-order-detail-shopping-item-label'}>{t(shoppingMethodLabel)} {t('Address')}</div>
            <div className={'my-order-detail-shopping-item-value'}>
              {
                addressInfo.street &&
                <span>{addressInfo.street}</span>
              }
              {
                addressInfo.city &&
                <span>, {addressInfo.city}</span>
              }
              {
                addressInfo.state &&
                <span>, {addressInfo.state}</span>
              }
              {
                addressInfo.zip &&
                <span>, {addressInfo.zip}</span>
              }
            </div>
          </div>
        </div>
        {
          isShowPaymentInformation &&
          <div className={'my-order-detail-payment-info-container'}>
            <div className={'my-order-detail-payment-info-title'}>{t('Payment Information')}</div>
            <div className={'my-order-detail-payment-info-line'}></div>
            <div className={'my-order-detail-payment-info-item-container'}>
              <div className={'my-order-detail-payment-info-item-label'}>{'Payment Method'}</div>
              <div className={'my-order-detail-payment-info-item-value-container'}>
                <div className={'my-order-detail-payment-info-item-value-icon-container'}>
                  <img src={paymentTypeList[1].label} alt=""/>
                </div>
                <div>**** **** **** 8395</div>
              </div>
            </div>
          </div>
        }
        {renderOrderSummary()}
        {!commonState.isMobile && <div className={'my-order-detail-bottom-container'}>
          <div
              className={'my-order-detail-btn'}
              onClick={() => handlePrintOrderDetail(orderDetailInfo.SalesOrder_id)}>
            {t('Print Order')}
          </div>
        </div>}
        {invoiceDetailInfo && invoiceDetailInfo.sales_invoice_id && renderInvoiceSummary()}
        {invoiceDetailInfo && invoiceDetailInfo.sales_invoice_id && <div className={'my-order-detail-bottom-container'}>
          <div
              className={'my-order-detail-btn'}
              onClick={() => handlePrintInvoiceDetail(invoiceDetailInfo.sales_invoice_id)}>
            {t('Print Invoice')}
          </div>
        </div>}
        {invoiceReturnInfo && invoiceReturnInfo.sales_return_id && renderInvoiceReturnSummary()}
        {invoiceReturnInfo && invoiceReturnInfo.sales_return_id && <div className={'my-order-detail-bottom-container'}>
          <div
              className={'my-order-detail-btn'}
              onClick={() => handlePrintInvoiceReturnDetail(invoiceReturnInfo.sales_return_id)}>
            {t('Print Return')}
          </div>
        </div>}
      </div>
      
    </div>
  )
}
