import React, {useState} from 'react'
import {useSelector} from 'react-redux';
import {Popover} from 'antd';

import './index.less'
import './mobile.less'

import {getUsingClassName} from '@/common';
// import wechatImg from '@/assets/img/wechat.jpg';
import wechatImg from '@/assets/img/success.png';

const defaultProps = {
  className: '',
};

export default function WechatPopover(props) {
  const options = Object.assign({}, defaultProps, props);

  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'wechat-popover-mobile-container' : 'wechat-popover-container'

  const [open, setOpen] = useState(false);
  const handleOpenChange = newOpen => {
    setOpen(newOpen);
  };
  
  const renderContent = () => <div
    className={'wechat-popover--content-container'}>
    <img src={wechatImg} alt=""/>
  </div>

  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  return (
    <Popover
      overlayClassName={className}
      content={renderContent()}
      open={open}
      onOpenChange={handleOpenChange}>
      {options.children}
    </Popover>
  )
}
