import React, {useCallback, useMemo, useRef, useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import {isFunction} from 'lodash';
import {useTranslation} from 'react-i18next'

import {LeftOutlined, MenuOutlined, SearchOutlined} from '@ant-design/icons'

import './index.less'
import './mobile.less'

import logoImg from '@/assets/img/logo.png'

import {changeLanguage} from '@/store/modules/common.slice';

import {getUsingClassName} from '@/common';
import {
  LOGOUT_EVENT_NAME,
  MY_LIST_KEY,
  MY_PROFILE_PATH_KEY,
  MY_PROFILE_INFORMATION_KEY,
} from '@/constants'
import eventBus from '@/common/EventBus';

const LANGUAGE_KEY = 'language'
const LOGOUT_KEY = 'Logout'
const productRootPath = '/ourProducts'
const productDetailPath = '/productDetail'
const myProfilePath = '/myProfile'
const myFullProfilePath = `${myProfilePath}?${MY_PROFILE_PATH_KEY}=${MY_PROFILE_INFORMATION_KEY}`
const myListPath = `${myProfilePath}?${MY_PROFILE_PATH_KEY}=${MY_LIST_KEY}`
const shoppingCartPath = '/shoppingCart'
const productMenuItem = {
  label: 'Product',
  path: productRootPath
}
const signupLoginMenuItem = {
  label: 'sign up/log in',
  path: '/signupLogin'
}
const LanguageMenuItem = {
  label: '中文 / EN',
  path: LANGUAGE_KEY
}
const LogoutMenuItem = {
  label: 'Log out',
  path: LOGOUT_KEY
}
const guestMenuList = [
  productMenuItem,
  signupLoginMenuItem,
  LanguageMenuItem,
]
const guestDetailPageMenuList = [
  signupLoginMenuItem,
  LanguageMenuItem,
]
const loggedDetailPageMenuList = [
  LogoutMenuItem,
  LanguageMenuItem,
]
const loggedMenuList = [
  productMenuItem,
  {
    label: 'My Profile',
    path: myFullProfilePath
  },
  // {
  //   label: 'My Favorites',
  //   path: myListPath
  // },
  {
    label: 'My Cart',
    path: shoppingCartPath
  },
  LogoutMenuItem,
  LanguageMenuItem,
]

const defaultProps = {
  isDetailPage: false,
  detailPageTitle: '',
  onLeftDetailPageClick: undefined,
  className: '',
  logoutIsGoHome: false,
  isShowHeaderRight: true,
  isShowSearch: true,
};

export default function Header(props) {
  const options = Object.assign({}, defaultProps, props);
  
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const commonState = useSelector(state => state.common)
  const cartState = useSelector(state => state.cart)
  const zipcode = commonState.zipcode
  const isLogged = commonState.isLogged
  const rootClassName = commonState.isMobile ? 'header-mobile-container' : 'header-container'
  const {orderCartTotalCount} = cartState
  const [menuHeight, setMenuHeight] = useState(0)
  const _menuHeight = useRef(0);
  const {isDetailPage} = options
  const mobileMenuRef = useCallback(elem => {
    if (elem) {
      _menuHeight.current = elem.getBoundingClientRect().height;
    }
  }, [isLogged]);

  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  const useMenuList = useMemo(
    () => {
      if (!isLogged) {
        return isDetailPage ? guestDetailPageMenuList : guestMenuList
      }
      return loggedMenuList
    }, 
    [isLogged]
  )

  const handleLeftDetailPageClick = () => {
    if (isFunction(options.onLeftDetailPageClick)) {
      return options.onLeftDetailPageClick()
    }
    navigate(-1)
  }
  
  const handleLogoutClick = () => {
    eventBus.dispatchEvent(LOGOUT_EVENT_NAME)
    options.logoutIsGoHome && navigate('/home')
  }
  const handleChangeLanguage = () => dispatch(changeLanguage())
  const handleLogoClick = () => navigate('/home')
  const handleSearchIconClick = () => navigate('/search')
  const handleMenuItemClick = (item, index) => {
    const {path} = item
    switch (path) {
      case LOGOUT_KEY:
        handleLogoutClick()
        break;
      case LANGUAGE_KEY:
        handleChangeLanguage()
        break;
      default:
        path !== location.pathname && navigate(path)
    }
  }
  const handleMenuIconClick = () => {
    setMenuHeight(menuHeight === 0 ? _menuHeight.current : 0)
  }
  
  const renderZipcode = () => <div className={'header-right-zipcode-container'}>
    <div className={'header-right-zipcode-title'}>{t('zipcode')}</div>
    <div className={'header-right-zipcode-val'}>
      {isLogged && zipcode}
    </div>
  </div>

  const renderSearch = () => {
    if(options.isShowSearch) {
      return <div onClick={handleSearchIconClick}>
        <SearchOutlined className='header-search-icon'/>
      </div>
    }
  }
  
  const renderMenuItem = (item, index) => {
    const {path} = item
    const {pathname, search} = location
    const fullPath = pathname + search
    const isProductPage = path === productRootPath && (pathname.includes(productRootPath) || pathname.includes(productDetailPath))
    const isAdd = path === fullPath || isProductPage
    const className = getUsingClassName(
      ['header-right-menu-item-container'],
      ['header-right-menu-item-active-container'],
      isAdd
    )
    
    return <div
      className={className}
      onClick={() => handleMenuItemClick(item, index)}
      key={'menu_item_' + index}>
      {t(item.label)}
      {
        item.path === shoppingCartPath &&
        !!orderCartTotalCount &&
        <span>({orderCartTotalCount})</span>
      }
    </div>
  }
  const renderMenu = () => useMenuList.map((item, index) => renderMenuItem(item, index))

  return (
    <div className={className}>
      <div className="header-left-wrapper">
        {
            options.isDetailPage && <div
                className={'header-left-detail-page-container'} onClick={handleLeftDetailPageClick}>
              <LeftOutlined className={'header-left-detail-page-icon'} />
              <div className={'header-left-detail-page-title overflow-ellipsis-1'}>
                {options.detailPageTitle}
              </div>
            </div>
        }
        {
            !options.isDetailPage &&
            <div
                className={'header-left-container'}
                onClick={handleLogoClick}>
              <img src={logoImg} alt="logo"/>
            </div>
        }
        {
            options.isShowHeaderRight && commonState.isMobile && <div className={'header-right-menu-icon'} onClick={handleMenuIconClick}>
              <MenuOutlined />
            </div>
        }
      </div>
      {
        options.isShowHeaderRight &&
        <div className={'header-right-container'} style={{
          height: commonState.isMobile ? menuHeight : undefined,
          opacity: commonState.isMobile ? menuHeight === 0 ? 0 : 1 : undefined
        }}>
          <div className="header-right-menu-wrapper" ref={mobileMenuRef}>
            {/* {renderZipcode()} */}
            {!commonState.isMobile && renderSearch()}
            {renderMenu()}
          </div>
        </div>
      }
    </div>
  )
}
