import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {cloneDeep, debounce} from 'lodash';

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import CartOrderSummary from '@/components/CartOrderSummary'
import MyOrderItem from '@/components/MyOrderItem';

import {
  getEditOrderIsGetOrderDetails,
  getEditOrderProductList,
  getShoppingMethodList,
  setEditOrderProductList,
  isOver8FromEast,
} from '@/common';
import {getSalesOrderDetails, getCartInfo, updateOrder} from '@/api/req-api'
import {message} from "antd";

const methodList = getShoppingMethodList()
export default function EditOrder() {
  const {id} = useParams();
  const navigate = useNavigate()
  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'edit-order-mobile-container' : 'edit-order-container'
  
  const layoutRef = useRef(null)

  const [orderDetailInfo, setOrderDetailInfo] = useState({})
  const [cartInfo, setCartInfo] = useState({})
  const [productList, setProductList] = useState([])
  const addressInfo = useMemo(() => {
    const shippingAddress = orderDetailInfo.Shipping_Address || {}
    const {Street, City, State, Zip, Telephone, FirstName} = shippingAddress
    return {
      name: FirstName,
      phone: Telephone,
      street: Street,
      city: City,
      state: State,
      zip: Zip,
    }
  }, [orderDetailInfo.Shipping_Address])
  const shoppingMethodLabel = useMemo(() => {
    return methodList.find(item => item.value === orderDetailInfo.ShippingMethod)?.label
  }, [orderDetailInfo.ShippingMethod])
  
  const isGetOrderDetails = getEditOrderIsGetOrderDetails()
  
  const getCartInfoByApi = () => {
    if (!productList?.length) {
      return setCartInfo({})
    }
    const params = {
      Sales_Order_Lines: productList,
      SalesOrder_id: id
    }
    getCartInfo(params).then(res => {
      setCartInfo(res.data)
    }).catch(() => {})
  }
  
  useEffect(() => {
    if (isGetOrderDetails) {
      return
    }
    setProductList(getEditOrderProductList())
  }, [])
  const getSalesOrderDetailsByApi = () => {
    getSalesOrderDetails(id).then(res => {
      setOrderDetailInfo(res.data)
      setCartInfo(res.data)
      if (!isGetOrderDetails) {
        return
      }
      setProductList(res.data?.Sales_Order_Line.filter(product => !product.IsForFree) || [])
    })
  }
  useEffect(() => {
    getSalesOrderDetailsByApi()
  }, [id])
  useEffect(() => {
    setEditOrderProductList(productList)
    getCartInfoByApi()
  }, [productList])

  const handleSelectMoreClick = () => {
    navigate('/editOrderProducts')
  }
  const handleRemoveItemClick = item => {
    const newProductList = cloneDeep(productList)
    const idx = newProductList.findIndex(ite => ite.Product_id === item.Product_id)
    if (idx < 0) {
      return
    }
    newProductList.splice(idx, 1)
    setProductList(newProductList)
  }
  const handleQuantityChange = debounce((value, item) => {
    const newProductList = cloneDeep(productList)
    const idx = newProductList.findIndex(ite => ite.Product_id === item.Product_id)
    if (idx < 0) {
      return
    }
    newProductList[idx].Quantity = Number(value)
    setProductList(newProductList)
  }, 300)
  const handleSaveClick = () => {
    if(isOver8FromEast(orderDetailInfo.OrderDate)) {
      message.warning(t('Order is packing，not allowed to modify.'))
      return
    }
    if (!productList?.length) {
      return message.warning(t('Please add product first'))
    }
    const params = {
      Sales_Order_Lines: productList,
      SalesOrder_id: id,
      IsCreditUsed: orderDetailInfo.IsCreditUsed,
      CreditAmount: orderDetailInfo.CreditAmount,
    }
    updateOrder(params).then(res => {
      setCartInfo(res.data)
      message.success(t('Saved successfully'))
    }).catch(() => {})
  }
  const renderOrderSummary = () => {
    return <CartOrderSummary
      className={'shopping-cart-right-container'}
      productList={cartInfo.Sales_Order_Lines}
      onQuantityChange={handleQuantityChange}
      onRemoveItemClick={handleRemoveItemClick}
      onBtnClick={handleSaveClick}
      subtotal={cartInfo?.TotalPrice}
      tax={cartInfo?.TaxAmount}
      promotion={cartInfo?.Savings}
      shipping={cartInfo?.Delivery}
      total={cartInfo?.FinalPrice}
      credit={cartInfo?.CreditAmount}
      btnText={'Save'}
      topRightBtnText={'Select More'}
      onTopRightBtnClick={handleSelectMoreClick}
      promotionProduct={cartInfo?.Promotions}
    />
  }
  
  return (
    <Layout
      ref={layoutRef}
      headerLogoutIsGoHome={true}
      isDetailPage={true}
      detailPageTitle={t('Edit Order')}>
      <div className={rootClassName}>
        <div className={'edit-order-content-container'}>
          <MyOrderItem
            item={orderDetailInfo}
            isShowViewOrderDetailsBtn={false}
            isShowReorderBtn={false}
          />
          <div className={'edit-order-shopping-container'}>
            <div className={'edit-order-shopping-item-container'}>
              <div className={'edit-order-shopping-item-label'}>{t(shoppingMethodLabel)} {t('Name')}</div>
              <div className={'edit-order-shopping-item-value'}>{addressInfo.name}</div>
            </div>
            <div className={'edit-order-shopping-item-container'}>
              <div className={'edit-order-shopping-item-label'}>{t(shoppingMethodLabel)} {t('Phone')}</div>
              <div className={'edit-order-shopping-item-value'}>{addressInfo.phone}</div>
            </div>
            <div className={'edit-order-shopping-item-container'}>
              <div className={'edit-order-shopping-item-label'}>{t(shoppingMethodLabel)} {t('Address')}</div>
              <div className={'edit-order-shopping-item-value'}>
                {
                  addressInfo.street &&
                  <span>{addressInfo.street}</span>
                }
                {
                  addressInfo.city &&
                  <span>, {addressInfo.city}</span>
                }
                {
                  addressInfo.state &&
                  <span>, {addressInfo.state}</span>
                }
                {
                  addressInfo.zip &&
                  <span>, {addressInfo.zip}</span>
                }
              </div>
            </div>
          </div>
          {renderOrderSummary()}
        </div>
      </div>
    </Layout>
  )
}
