import React, {forwardRef, useImperativeHandle} from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next'
import {InputNumber} from 'antd';

import './index.less'
import './mobile.less'

import {getFormatMoney, getUsingClassName} from '@/common';

import noImage from '@/assets/img/icon/noImage.png';

const defaultProps = {
  className: '',
  productList: [],
  onRemoveItemClick: () => {},
  onQuantityChange: () => {},
  btnText: 'Place An order',
  onBtnClick: () => {},
  subtotal: '',
  tax: '',
  promotion: '',
  shipping: '',
  total: '',
  topRightBtnText: '',
  onTopRightBtnClick: () => {},
};

const CartOrderSummary = forwardRef(function (props, ref) {
  const options = Object.assign({}, defaultProps, props);

  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'cart-order-summary-mobile-container' : 'cart-order-summary-container'
  
  const {
    productList,
    onRemoveItemClick,
    onQuantityChange,
    btnText,
    onBtnClick,
    subtotal,
    tax,
    promotion,
    shipping,
    total,
    credit,
    topRightBtnText,
    promotionProduct,
    onTopRightBtnClick
  } = options

  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  useImperativeHandle(ref, () => ({
  }))
  
  return (
    <div className={className}>
      <div className={'cart-order-summary-top-container'}>
        <div className={'cart-order-summary-title'}>{t('Order Summary')}</div>
        <div
          className={'cart-order-summary-top-btn'}
          onClick={onTopRightBtnClick}>
          {t(topRightBtnText)}
        </div>
      </div>
      <div className={'cart-order-summary-line'}></div>
      <div className={'cart-order-summary-product-list-container'}>
        {
          productList?.map((item, index) => <div
            className={'cart-order-summary-product-list-item-container'}
            key={`product_list_${index}`}>
            <div className={'cart-order-summary-product-list-item-left-container'}>
              <div className={'cart-order-summary-product-list-item-left-img-container'}>
                <img src={item.Thumbnail || noImage} alt=""/>
              </div>
              <div className={'cart-order-summary-product-list-item-left-qty-container'}>
                <div className={'cart-order-summary-product-list-item-left-qty-title'}>
                  {t('QTY')}
                </div>
                <InputNumber
                  className={'cart-order-summary-product-list-item-left-qty-input'}
                  min={1}
                  value={item.Quantity}
                  onStep={value => onQuantityChange(value, item)}
                  onPressEnter={event => onQuantityChange(event.target.value, item)}
                  onBlur={event => onQuantityChange(event.target.value, item)}
                />
              </div>
            </div>
            <div className={'cart-order-summary-product-list-item-right-container'}>
              <div className={'cart-order-summary-product-list-item-right-info-container'}>
                <div className={'cart-order-summary-product-list-item-right-info-title'}>
                  {item.Name}
                </div>
                <div className={'cart-order-summary-product-list-item-right-info-price'}>
                  ${getFormatMoney(item.UnitPrice || item.Price)}
                  {
                    item.ProductType === 'Non-Fixed' ?
                    <span>&nbsp;/ {item.MeasureUnit}</span>
                    :
                    <span>&nbsp;/ {item.MeasureUnit_2}</span>
                  }
                </div>
                <div className={'cart-order-summary-product-list-item-right-info-size'}>
                  {t('Size')}: {item.PackageSize}
                </div>
              </div>
              <div
                className={'cart-order-summary-product-list-item-right-btn'}
                onClick={() => onRemoveItemClick(item)}>
                {t('Remove item')}
              </div>
            </div>
          </div>)
        }
        {
          promotionProduct?.map((item, index) =><div
              className={'cart-order-summary-product-list-item-container'}
              key={`product_list_${index}`}>
            <div className={'cart-order-summary-product-list-item-left-container'}>
              <div className={'cart-order-summary-product-list-item-left-img-container'}>
                <img src={item.Thumbnail || noImage} alt=""/>
              </div>
              <div className={'cart-order-summary-product-list-item-left-qty-container'}>
                <div className={'cart-order-summary-product-list-item-left-qty-title'}>
                  {t('QTY')}
                </div>
                <InputNumber
                    className={'cart-order-summary-product-list-item-left-qty-input'}
                    min={1}
                    disabled
                    value={item.Quantity}
                />
              </div>
            </div>
            <div className={'cart-order-summary-product-list-item-right-container'}>
              <div className={'cart-order-summary-product-list-item-right-info-container'}>
                <div className={'cart-order-summary-product-list-item-right-info-title'}>
                  {item.Name}
                </div>
                <div className={'cart-order-summary-product-list-item-right-info-price'}>
                  {item.IsForFree ? t('Free') : '$' + getFormatMoney(item.UnitPrice || item.Price)}
                </div>
                <div className={'cart-order-summary-product-list-item-right-info-size'}>
                  {t('Size')}: {item.PackageSize}
                </div>
              </div>
            </div>
          </div>)
        }
      </div>
      <div className={'cart-order-summary-line'}></div>
      <div className={'cart-order-summary-order-info-container'}>
        <div className={'cart-order-summary-order-info-item-container'}>
          <div className={'cart-order-summary-order-info-item-title'}>{t('Subtotal')}</div>
          <div className={'cart-order-summary-order-info-item-val'}>${getFormatMoney(subtotal)}</div>
        </div>
        <div className={'cart-order-summary-order-info-item-container'}>
          <div className={'cart-order-summary-order-info-item-title'}>{t('Tax')}</div>
          <div className={'cart-order-summary-order-info-item-val'}>${getFormatMoney(tax)}</div>
        </div>
        <div className={'cart-order-summary-order-info-item-container'}>
          <div className={'cart-order-summary-order-info-item-title'}>{t('Promotion')}</div>
          <div className={'cart-order-summary-order-info-item-val'}>${getFormatMoney(promotion)}</div>
        </div>
        <div className={'cart-order-summary-order-info-item-container'}>
          <div className={'cart-order-summary-order-info-item-title'}>{t('Shipping')}</div>
          <div className={'cart-order-summary-order-info-item-val'}>${getFormatMoney(shipping)}</div>
        </div>
        {/* <div className={'cart-order-summary-order-info-item-container'}>
          <div className={'cart-order-summary-order-info-item-title'}>{t('Transaction Fee 3%')}</div>
          <div className={'cart-order-summary-order-info-item-val'}>${getFormatMoney((subtotal * 0.03).toFixed(2))}</div>
        </div> */}
        {!!credit && <div className={'cart-order-summary-order-info-item-container'}>
          <div className={'cart-order-summary-order-info-item-title'}>{t('Credit')}</div>
          <div className={'cart-order-summary-order-info-item-val'}>${getFormatMoney(credit)}</div>
        </div>}
        <div className={'cart-order-summary-line cart-order-summary-order-info-line'}></div>
        <div className={'cart-order-summary-order-info-item-container'}>
          <div className={'cart-order-summary-order-info-item-title'}>{t('Total')}</div>
          <div className={'cart-order-summary-order-info-item-val'}>${getFormatMoney(total)}</div>
        </div>
      </div>
      <div className={'cart-order-summary-btn'} onClick={onBtnClick}>
        {t(btnText)}
      </div>
    </div>
  )
})

export default CartOrderSummary
